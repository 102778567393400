import * as React from 'react';
import { useEffect, useContext, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { RotatingLines } from 'react-loader-spinner';
import {Amplify, API, graphqlOperation, Storage} from 'aws-amplify';
import styled from 'styled-components';
// import { HttpRequest } from '@aws-sdk/protocol-http';
// import { Signer } from '@aws-amplify/core';
// import { Credentials } from 'aws-sdk';
import WidgetDrinklinkAdmin from "../../components/widget/WidgetDrinklinkAdmin";
import FeaturedDrinklinkAdmin from "../../components/featured/FeaturedDrinklinkAdmin";
import Chart from "../../components/chart/Chart";
import { searchMenuItemsPurchaseds, searchUsers } from "../../graphql/queries";


//import * as queries from "../../../src/graphql/queries";
import awsconfig from '../../../src/aws-exports';
import { AuthContext } from '../../context/AuthContext';
import { DrinklinkAdminContext } from '../../context/DrinklinkAdminContext';
import SidebarDrinklinkAdmin from '../../components/sidebar/SidebarDrinklinkAdmin'
import TopNavBar from '../Landing/topNavBar';
import Footer from '../Landing/footer';
import {Spinner} from 'react-bootstrap';

const InstructionsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
`;

const InstructionsItem = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
`;

const InstructionsColorCreated = styled.div`
    padding: 10px;
    background-color: #2A3841;
    color: white;
`;

const InstructionsColorActive = styled.div`
    padding: 10px;
    background-color: #844454;
    color: white;
`;

const InstructionsColorInactive = styled.div`
    padding: 10px;
    background-color: grey;
    color: black;
`;

const InstructionsItemText = styled.div`
    margin-left: 10px;
`

const Main = () => {
    const {currentUser, dispatch: authDispatch} = useContext(AuthContext);
    const {getTheData, dispatch: adminDispatch} = useContext(DrinklinkAdminContext)
    const [doneFetchingTheData, setDoneFetchingTheData] = useState(false);
    const [theData, setTheData] = useState({
            users: 30, //null, //34,
            orders: 1354,
            earnings: 10234,
            payouts: 8945
    });
    const [isLoading, setIsLoading] = useState(true);
    const [totalUsers, setTotalUsers] = useState(0);

    const navigate = useNavigate();
    const [newVendorModalOpen, setNewVendorModalOpen] = useState(false);

    console.log('currentUser', currentUser)

    Amplify.configure(awsconfig);

    console.log('theData in hom', theData)

    const fetchTotalUsers = async () => {
        try {
            // const userResponse = await API.graphql(graphqlOperation(searchUsers, {
            //     filter: {},
            // }));

            const userCount = 12; //userResponse.data.searchUsers.total
            console.log('userCount', userCount)
            const oldData = theData;
            oldData.users = userCount;
            setTheData(oldData);
        } catch (e) {
            console.log('error fetching users', e);
        }
    };

    const fetchTotalOrders = async () => {
        try {
            // const userResponse = await API.graphql(graphqlOperation(searchMenuItemsPurchaseds, {
            //     filter: {},
            //     aggregates: [
            //         {
            //             type: "sum",
            //             field: "drinkCost",
            //             name: "totalDrinksCost"
            //         },
            //         {
            //             type: "sum",
            //             field: "tip",
            //             name: "totalTips"
            //         },
            //         {
            //             type: "sum",
            //             field: "fees",
            //             name: "totalFees"
            //         }
            //     ]
            // }));

            // const orderCount = userResponse.data.searchMenuItemsPurchaseds.total
            // const orderData = userResponse.data.searchMenuItemsPurchaseds.aggregateItems
            // console.log('userCount', orderCount)
            // console.log('orderData', orderData)
            const oldData = theData;
            oldData.orders = 10; //orderCount;
            setTheData(oldData);
        } catch (e) {
            console.log('error fetching users', e);
        }
    };

    useEffect(() => {
        const onGetData = async () => {
            await getTheData()
            await fetchTotalUsers();
            await fetchTotalOrders();
            setIsLoading(false)
            setDoneFetchingTheData(true);
        }

        onGetData()
    },[])

    // useEffect(() => {
    //     const fetchVendors = async () => {
    //         console.log('in fetchVendors currentUser', currentUser);
    //         if (currentUser.id) {
    //             try {
    //                 const userVendors = await getUserVendors(currentUser.id);
    //                 const parsedUserVendors = userVendors && userVendors.length > 0 ? userVendors : [];
    //                 dispatch({ type: "SET_USER_VENDORS", payload: parsedUserVendors});
    //             } catch (err) {
    //                 console.log('error:' , err);
    //             }
    //         }
    //     };
    //     fetchVendors();
    // }, [currentUser]);


    // const handleLogout = async(e) => {
    //     try {
    //         await Auth.signOut()
    //         authDispatch({ type: "LOGOUT", payload: null});
    //         dispatch({type: "UNSET_USER_VENDORS", payload: null});
    //         dispatch({type: "UNSET_USER_VENDOR_SELECTED", payload: null})
    //         navigate("/");
    //     } catch (err) {
    //         console.log('error signing out: ', err)
    //     }
    // }

    return (
        <>
        <TopNavBar />
        <div className="home">
        <SidebarDrinklinkAdmin />
        {isLoading ? 
            <Spinner></Spinner>
        :
            <div className="homeContainer">
            {doneFetchingTheData && 
                <div className="widgets">
                    <WidgetDrinklinkAdmin type="user" theData={theData}/>
                    <WidgetDrinklinkAdmin type="order" theData={theData}/>
                    <WidgetDrinklinkAdmin type="earning" theData={theData}/>
                    <WidgetDrinklinkAdmin type="paid-out" theData={theData}/>
                </div>
            }
            <div className="charts">
                <FeaturedDrinklinkAdmin />
                <Chart title="Last 6 Months (Revenue)" aspect={2 / 1} />
            </div>
            <div className="listContainer">
                <div className="listTitle">Latest Transactions</div>
                {/*<Table /> */}
            </div>
            </div>
        }
      </div>
        <Footer />
        </>
    )
}

export default Main;