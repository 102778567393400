/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUsers = /* GraphQL */ `
  query GetUsers($id: ID!) {
    getUsers(id: $id) {
      id
      avatarImage
      birthDate
      email
      fName
      homeCity
      homeState
      isVendorUser
      lName
      lowerCaseUsername
      phone
      smsNotificationsAccept
      stripeCustomerId
      username
      emailNotificationsAccept
      userAskedToDelete
      deletedAt
      Friends {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        avatarImage
        birthDate
        email
        fName
        homeCity
        homeState
        isVendorUser
        lName
        lowerCaseUsername
        phone
        smsNotificationsAccept
        stripeCustomerId
        username
        emailNotificationsAccept
        userAskedToDelete
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMainCategories = /* GraphQL */ `
  query GetMainCategories($id: ID!) {
    getMainCategories(id: $id) {
      id
      MenuItems {
        nextToken
        __typename
      }
      name
      vendorsID
      subCategories {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMainCategories = /* GraphQL */ `
  query ListMainCategories(
    $filter: ModelMainCategoriesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMainCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        vendorsID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSubCategories = /* GraphQL */ `
  query GetSubCategories($id: ID!) {
    getSubCategories(id: $id) {
      id
      MenuItems {
        nextToken
        __typename
      }
      name
      vendorsID
      mainCategoriesID
      mainCategory {
        id
        name
        vendorsID
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSubCategories = /* GraphQL */ `
  query ListSubCategories(
    $filter: ModelSubCategoriesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        vendorsID
        mainCategoriesID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMenuItems = /* GraphQL */ `
  query GetMenuItems($id: ID!) {
    getMenuItems(id: $id) {
      id
      description
      drink
      price
      vendorsID
      mainCategoriesID
      mainCategory {
        id
        name
        vendorsID
        createdAt
        updatedAt
        __typename
      }
      subCategoriesID
      subCategory {
        id
        name
        vendorsID
        mainCategoriesID
        createdAt
        updatedAt
        __typename
      }
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMenuItems = /* GraphQL */ `
  query ListMenuItems(
    $filter: ModelMenuItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMenuItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        description
        drink
        price
        vendorsID
        mainCategoriesID
        subCategoriesID
        type
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMenuItemsPurchased = /* GraphQL */ `
  query GetMenuItemsPurchased($id: ID!) {
    getMenuItemsPurchased(id: $id) {
      id
      category
      personalMessage
      state
      stateHistory
      stripeStatus
      stripeStatusHistory
      stripeReceiptUrl
      drinkCost
      fees
      menuItemDescription
      menuItemName
      stripePaymentIntentID
      tip
      purchaserID
      purchaser {
        id
        avatarImage
        birthDate
        email
        fName
        homeCity
        homeState
        isVendorUser
        lName
        lowerCaseUsername
        phone
        smsNotificationsAccept
        stripeCustomerId
        username
        emailNotificationsAccept
        userAskedToDelete
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      receiverID
      receiver {
        id
        avatarImage
        birthDate
        email
        fName
        homeCity
        homeState
        isVendorUser
        lName
        lowerCaseUsername
        phone
        smsNotificationsAccept
        stripeCustomerId
        username
        emailNotificationsAccept
        userAskedToDelete
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      vendorsID
      vendor {
        id
        status
        vendorDisplayStatus
        vendorCity
        vendorContactPhone
        vendorEmail
        vendorImage
        vendorName
        vendorState
        vendorStreet
        vendorZip
        waveDrinklinkFee
        feeScheduleID
        createdAt
        updatedAt
        __typename
      }
      vendorNotes
      vendorPaymentID
      vendorPayment {
        id
        amount
        paymentDate
        vendorsID
        adminUserID
        paidBy
        invoiceNumber
        invoiceURL
        createdAt
        updatedAt
        __typename
      }
      nonMemberToken
      nonMemberPhone
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMenuItemsPurchaseds = /* GraphQL */ `
  query ListMenuItemsPurchaseds(
    $filter: ModelMenuItemsPurchasedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMenuItemsPurchaseds(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        category
        personalMessage
        state
        stateHistory
        stripeStatus
        stripeStatusHistory
        stripeReceiptUrl
        drinkCost
        fees
        menuItemDescription
        menuItemName
        stripePaymentIntentID
        tip
        purchaserID
        receiverID
        vendorsID
        vendorNotes
        vendorPaymentID
        nonMemberToken
        nonMemberPhone
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getVendorUsers = /* GraphQL */ `
  query GetVendorUsers($id: ID!) {
    getVendorUsers(id: $id) {
      id
      lastAccess
      role
      status
      vendorsID
      vendors {
        id
        status
        vendorDisplayStatus
        vendorCity
        vendorContactPhone
        vendorEmail
        vendorImage
        vendorName
        vendorState
        vendorStreet
        vendorZip
        waveDrinklinkFee
        feeScheduleID
        createdAt
        updatedAt
        __typename
      }
      usersID
      users {
        id
        avatarImage
        birthDate
        email
        fName
        homeCity
        homeState
        isVendorUser
        lName
        lowerCaseUsername
        phone
        smsNotificationsAccept
        stripeCustomerId
        username
        emailNotificationsAccept
        userAskedToDelete
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listVendorUsers = /* GraphQL */ `
  query ListVendorUsers(
    $filter: ModelVendorUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVendorUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        lastAccess
        role
        status
        vendorsID
        usersID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDrinklinkAdmins = /* GraphQL */ `
  query GetDrinklinkAdmins($id: ID!) {
    getDrinklinkAdmins(id: $id) {
      id
      lastAccess
      role
      status
      usersID
      users {
        id
        avatarImage
        birthDate
        email
        fName
        homeCity
        homeState
        isVendorUser
        lName
        lowerCaseUsername
        phone
        smsNotificationsAccept
        stripeCustomerId
        username
        emailNotificationsAccept
        userAskedToDelete
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDrinklinkAdmins = /* GraphQL */ `
  query ListDrinklinkAdmins(
    $filter: ModelDrinklinkAdminsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDrinklinkAdmins(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        lastAccess
        role
        status
        usersID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBartenders = /* GraphQL */ `
  query GetBartenders($id: ID!) {
    getBartenders(id: $id) {
      id
      fName
      lName
      otherIdentifier
      showOnRedeem
      vendorsID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listBartenders = /* GraphQL */ `
  query ListBartenders(
    $filter: ModelBartendersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBartenders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        fName
        lName
        otherIdentifier
        showOnRedeem
        vendorsID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getVendors = /* GraphQL */ `
  query GetVendors($id: ID!) {
    getVendors(id: $id) {
      id
      status
      vendorDisplayStatus
      vendorCity
      vendorContactPhone
      vendorEmail
      vendorImage
      vendorName
      vendorState
      vendorStreet
      vendorZip
      MenuItems {
        nextToken
        __typename
      }
      waveDrinklinkFee
      feeScheduleID
      feeSchedule {
        id
        fee
        name
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listVendors = /* GraphQL */ `
  query ListVendors(
    $filter: ModelVendorsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVendors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        status
        vendorDisplayStatus
        vendorCity
        vendorContactPhone
        vendorEmail
        vendorImage
        vendorName
        vendorState
        vendorStreet
        vendorZip
        waveDrinklinkFee
        feeScheduleID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFriends = /* GraphQL */ `
  query GetFriends($id: ID!) {
    getFriends(id: $id) {
      id
      usersID
      friendUserID
      friendUser {
        id
        avatarImage
        birthDate
        email
        fName
        homeCity
        homeState
        isVendorUser
        lName
        lowerCaseUsername
        phone
        smsNotificationsAccept
        stripeCustomerId
        username
        emailNotificationsAccept
        userAskedToDelete
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listFriends = /* GraphQL */ `
  query ListFriends(
    $filter: ModelFriendsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFriends(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        usersID
        friendUserID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMenuItemsSentToBar = /* GraphQL */ `
  query GetMenuItemsSentToBar($id: ID!) {
    getMenuItemsSentToBar(id: $id) {
      receiverName
      id
      itemID
      item {
        id
        category
        personalMessage
        state
        stateHistory
        stripeStatus
        stripeStatusHistory
        stripeReceiptUrl
        drinkCost
        fees
        menuItemDescription
        menuItemName
        stripePaymentIntentID
        tip
        purchaserID
        receiverID
        vendorsID
        vendorNotes
        vendorPaymentID
        nonMemberToken
        nonMemberPhone
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMenuItemsSentToBars = /* GraphQL */ `
  query ListMenuItemsSentToBars(
    $filter: ModelMenuItemsSentToBarFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMenuItemsSentToBars(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        receiverName
        id
        itemID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getVendorPayments = /* GraphQL */ `
  query GetVendorPayments($id: ID!) {
    getVendorPayments(id: $id) {
      id
      amount
      paymentDate
      menuItemsPurchased {
        nextToken
        __typename
      }
      vendorsID
      vendor {
        id
        status
        vendorDisplayStatus
        vendorCity
        vendorContactPhone
        vendorEmail
        vendorImage
        vendorName
        vendorState
        vendorStreet
        vendorZip
        waveDrinklinkFee
        feeScheduleID
        createdAt
        updatedAt
        __typename
      }
      adminUserID
      adminUser {
        id
        avatarImage
        birthDate
        email
        fName
        homeCity
        homeState
        isVendorUser
        lName
        lowerCaseUsername
        phone
        smsNotificationsAccept
        stripeCustomerId
        username
        emailNotificationsAccept
        userAskedToDelete
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      paidBy
      invoiceNumber
      invoiceURL
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listVendorPayments = /* GraphQL */ `
  query ListVendorPayments(
    $filter: ModelVendorPaymentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVendorPayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        amount
        paymentDate
        vendorsID
        adminUserID
        paidBy
        invoiceNumber
        invoiceURL
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFeeSchedule = /* GraphQL */ `
  query GetFeeSchedule($id: ID!) {
    getFeeSchedule(id: $id) {
      id
      fee
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listFeeSchedules = /* GraphQL */ `
  query ListFeeSchedules(
    $filter: ModelFeeScheduleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFeeSchedules(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        fee
        name
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getVendorRequestAccess = /* GraphQL */ `
  query GetVendorRequestAccess($id: ID!) {
    getVendorRequestAccess(id: $id) {
      id
      contactName
      contactEmail
      contactPhone
      vendorName
      vendorContactPhone
      vendorStreet
      vendorCity
      vendorState
      vendorZip
      contactsMade
      state
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listVendorRequestAccesses = /* GraphQL */ `
  query ListVendorRequestAccesses(
    $filter: ModelVendorRequestAccessFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVendorRequestAccesses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        contactName
        contactEmail
        contactPhone
        vendorName
        vendorContactPhone
        vendorStreet
        vendorCity
        vendorState
        vendorZip
        contactsMade
        state
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userByEmail = /* GraphQL */ `
  query UserByEmail(
    $email: AWSEmail!
    $sortDirection: ModelSortDirection
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        avatarImage
        birthDate
        email
        fName
        homeCity
        homeState
        isVendorUser
        lName
        lowerCaseUsername
        phone
        smsNotificationsAccept
        stripeCustomerId
        username
        emailNotificationsAccept
        userAskedToDelete
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userByPhone = /* GraphQL */ `
  query UserByPhone(
    $phone: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByPhone(
      phone: $phone
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        avatarImage
        birthDate
        email
        fName
        homeCity
        homeState
        isVendorUser
        lName
        lowerCaseUsername
        phone
        smsNotificationsAccept
        stripeCustomerId
        username
        emailNotificationsAccept
        userAskedToDelete
        deletedAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const mainCategoriesByVendorsID = /* GraphQL */ `
  query MainCategoriesByVendorsID(
    $vendorsID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMainCategoriesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    mainCategoriesByVendorsID(
      vendorsID: $vendorsID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        vendorsID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const subCategoriesByVendorsID = /* GraphQL */ `
  query SubCategoriesByVendorsID(
    $vendorsID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSubCategoriesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    subCategoriesByVendorsID(
      vendorsID: $vendorsID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        vendorsID
        mainCategoriesID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const subCategoriesByMainCategoriesID = /* GraphQL */ `
  query SubCategoriesByMainCategoriesID(
    $mainCategoriesID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSubCategoriesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    subCategoriesByMainCategoriesID(
      mainCategoriesID: $mainCategoriesID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        vendorsID
        mainCategoriesID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const menuItemsByVendorsID = /* GraphQL */ `
  query MenuItemsByVendorsID(
    $vendorsID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMenuItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    menuItemsByVendorsID(
      vendorsID: $vendorsID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        description
        drink
        price
        vendorsID
        mainCategoriesID
        subCategoriesID
        type
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const menuItemsByMainCategoriesID = /* GraphQL */ `
  query MenuItemsByMainCategoriesID(
    $mainCategoriesID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMenuItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    menuItemsByMainCategoriesID(
      mainCategoriesID: $mainCategoriesID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        description
        drink
        price
        vendorsID
        mainCategoriesID
        subCategoriesID
        type
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const menuItemsBySubCategoriesID = /* GraphQL */ `
  query MenuItemsBySubCategoriesID(
    $subCategoriesID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMenuItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    menuItemsBySubCategoriesID(
      subCategoriesID: $subCategoriesID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        description
        drink
        price
        vendorsID
        mainCategoriesID
        subCategoriesID
        type
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const menuItemsByDrink = /* GraphQL */ `
  query MenuItemsByDrink(
    $type: String!
    $drink: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMenuItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    menuItemsByDrink(
      type: $type
      drink: $drink
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        description
        drink
        price
        vendorsID
        mainCategoriesID
        subCategoriesID
        type
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const menuItemsPurchasedByStripePaymentIntentID = /* GraphQL */ `
  query MenuItemsPurchasedByStripePaymentIntentID(
    $stripePaymentIntentID: String!
    $sortDirection: ModelSortDirection
    $filter: ModelMenuItemsPurchasedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    menuItemsPurchasedByStripePaymentIntentID(
      stripePaymentIntentID: $stripePaymentIntentID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        category
        personalMessage
        state
        stateHistory
        stripeStatus
        stripeStatusHistory
        stripeReceiptUrl
        drinkCost
        fees
        menuItemDescription
        menuItemName
        stripePaymentIntentID
        tip
        purchaserID
        receiverID
        vendorsID
        vendorNotes
        vendorPaymentID
        nonMemberToken
        nonMemberPhone
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const menuItemsPurchasedByVendorPayment = /* GraphQL */ `
  query MenuItemsPurchasedByVendorPayment(
    $vendorPaymentID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMenuItemsPurchasedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    menuItemsPurchasedByVendorPayment(
      vendorPaymentID: $vendorPaymentID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        category
        personalMessage
        state
        stateHistory
        stripeStatus
        stripeStatusHistory
        stripeReceiptUrl
        drinkCost
        fees
        menuItemDescription
        menuItemName
        stripePaymentIntentID
        tip
        purchaserID
        receiverID
        vendorsID
        vendorNotes
        vendorPaymentID
        nonMemberToken
        nonMemberPhone
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const vendorUsersByVendorsID = /* GraphQL */ `
  query VendorUsersByVendorsID(
    $vendorsID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelVendorUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    vendorUsersByVendorsID(
      vendorsID: $vendorsID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lastAccess
        role
        status
        vendorsID
        usersID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const vendorUsersByUsersID = /* GraphQL */ `
  query VendorUsersByUsersID(
    $usersID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelVendorUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    vendorUsersByUsersID(
      usersID: $usersID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lastAccess
        role
        status
        vendorsID
        usersID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const drinklinkAdminsByUsersID = /* GraphQL */ `
  query DrinklinkAdminsByUsersID(
    $usersID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDrinklinkAdminsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    drinklinkAdminsByUsersID(
      usersID: $usersID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lastAccess
        role
        status
        usersID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const bartendersByVendorsID = /* GraphQL */ `
  query BartendersByVendorsID(
    $vendorsID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBartendersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bartendersByVendorsID(
      vendorsID: $vendorsID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fName
        lName
        otherIdentifier
        showOnRedeem
        vendorsID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const vendorByCity = /* GraphQL */ `
  query VendorByCity(
    $vendorCity: String!
    $sortDirection: ModelSortDirection
    $filter: ModelVendorsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    vendorByCity(
      vendorCity: $vendorCity
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        status
        vendorDisplayStatus
        vendorCity
        vendorContactPhone
        vendorEmail
        vendorImage
        vendorName
        vendorState
        vendorStreet
        vendorZip
        waveDrinklinkFee
        feeScheduleID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const vendorByName = /* GraphQL */ `
  query VendorByName(
    $vendorName: String!
    $sortDirection: ModelSortDirection
    $filter: ModelVendorsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    vendorByName(
      vendorName: $vendorName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        status
        vendorDisplayStatus
        vendorCity
        vendorContactPhone
        vendorEmail
        vendorImage
        vendorName
        vendorState
        vendorStreet
        vendorZip
        waveDrinklinkFee
        feeScheduleID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const vendorByState = /* GraphQL */ `
  query VendorByState(
    $vendorState: String!
    $sortDirection: ModelSortDirection
    $filter: ModelVendorsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    vendorByState(
      vendorState: $vendorState
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        status
        vendorDisplayStatus
        vendorCity
        vendorContactPhone
        vendorEmail
        vendorImage
        vendorName
        vendorState
        vendorStreet
        vendorZip
        waveDrinklinkFee
        feeScheduleID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const vendorsByFeeScheduleID = /* GraphQL */ `
  query VendorsByFeeScheduleID(
    $feeScheduleID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelVendorsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    vendorsByFeeScheduleID(
      feeScheduleID: $feeScheduleID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        status
        vendorDisplayStatus
        vendorCity
        vendorContactPhone
        vendorEmail
        vendorImage
        vendorName
        vendorState
        vendorStreet
        vendorZip
        waveDrinklinkFee
        feeScheduleID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const friendsByUsersID = /* GraphQL */ `
  query FriendsByUsersID(
    $usersID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelFriendsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    friendsByUsersID(
      usersID: $usersID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        usersID
        friendUserID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
